import React, { useContext, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home.js";
import Contact from "./Components/Contact";
import { Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import About from "./Components/Aboutcomp/About";
import Privacy from "./Components/Aboutcomp/Privacy";
import Mainblog from "./Components/Blog/Mainblog";
import Pingfederate from "./Components/Trainingcomp/Pingfederate";
import Nav from "./Components/Nav/Navbar";
import Faq from "./Components/Aboutcomp/Faq";
import Blog from "./Components/Blog/Blog1";
import Term from "./Components/Aboutcomp/Terncondition";
import Mission from "./Components/Aboutcomp/Mission";
import Profile from "./Components/Dashboard/Profile";
import Admindashboard from "./Components/Dashboard/Admindashboard";
import Dash from "./Components/Dashboard/Dash";
import CookieConsent from 'react-cookie-consent';
import Axios from 'axios'
import Cookies from 'js-cookie';

//import Training from './Components/Servicecomp.js/Training';
import Payment_page from "./Components/Trainingcomp/Payment_page";
import Idam from "./Components/Servicecomp.js/Idam";
import Forgerock from "./Components/Trainingcomp/Forgerock";
import Okta from "./Components/Trainingcomp/Okta";
import Onlogin from "./Components/Trainingcomp/Onlogin";
import Auth0 from "./Components/Trainingcomp/Auth0";
import Terncondition from "./Components/Aboutcomp/Terncondition";
import Refund from "./Components/Aboutcomp/Refund";
import Editinfo from "./Components/Dashboard/Editinfo";
import Allconsultant from "./Components/Trainingcomp/All-consultant";
import Consultant from "./Components/Consultant/Consultant";
import Event from './Components/Events/Events'
import AdminPanel from "./Components/AdminPanel/AdminPanel";
import Login from "../src/src/Pages/Auth/Login";
import Register from "../src/src/Pages/Auth/Register";
import Training from "../src/src/Pages/Home/Training_detail";
import Payment from "../src/src/Component/Training/Payment";
import Overview from "../src/src/Component/Dashboard/Overview";
import All_training from "../src/src/Pages/Training/All_training";
import New_Training from "../src/src/App";
import Self_learning from "../src/src/Component/Training/Self_learning";
import Forget_pswd from "../src/src/Component/User/Frgt_pswd";
import AllSevicesComp from "./Components/Servicecomp.js/AllSevicesComp";
import {Helmet} from 'react-helmet'
import Whatsup from '../src/src/Component/Fotter/img/whatsapp.png';
import MainWebinar from "./src/Component/Webinar/MainWebinar.js";
function App() {
  useEffect(() => {
    window.location.href = 'https://learn.askmeidentity.com'
    window.scrollTo(0, 0);
  }, []);
  const whatsappStyle = {
 // Adjust this value to position the icon horizontally
  };
  
  const subdomain = window.location.hostname.split(".")[0]; // Extract subdomain from the URL
  console.log(subdomain);
  // const { token } = useContext(firebaseAuth)
  // console.log(token)
  const handleAccept = async() => {
    Cookies.set('cookieConsent', 'true', { expires: 365 }); // Set a cookie to indicate consent
    Axios.get("https://ipapi.co/json/")
    .then(async(response) => {
      let dataa = response.data;
    
      const randomID = generateUniqueID(10);
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
  
      const userInfo = {
        id: randomID,
        isMobile: isMobile,
        ipData: dataa,
        date:new Date()
      };
      var encodedData=JSON.stringify(userInfo);
   
     Cookies.set('UserInfo', encodedData, { expires: 30 });

     await Axios.post(`${process.env.REACT_APP_BASE_URL}/Cookies`, {
      UserInfo: userInfo,
    })
      .then((response) => {
        console.log(response.data);

      })
      .catch((err) => {
        console.error(err);
      });    })
    function generateUniqueID(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let uniqueID = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueID += characters.charAt(randomIndex);
      }
    
      return uniqueID;
    }
  };
  if (subdomain == "training") {
    return (

      <>
       <div className="containerStyle">
    <a href="https://api.whatsapp.com/send?phone=8184814546" className="whatsappStyle">
      <img src={Whatsup} alt="" />
    </a>
  </div>
        <Routes>
          <Route path="/faq" element={<Faq />} />
          <Route path="/form" element={<Editinfo />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/term" element={<Terncondition />} />
          <Route path="/admin" element={<Admindashboard />} />
          <Route path="/iam-consultant" element={<Consultant />} />
          <Route path="/home" element={<New_Training />} />
          <Route path="/" element={<New_Training />} />
          <Route path="/dashboard" element={<Dash />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/training-detail" element={<Training />} />
          <Route path="/mainblog" element={<Mainblog />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/consultant" element={<Consultant />} />
          <Route path="/term" element={<Term />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/idam" element={<Idam />} />
          <Route path="/training" element={<Training />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/askmeidentity-training" element={<New_Training/>} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/user-overview" element={<Overview />} />
          <Route path="/all-training" element={<All_training />} />
          <Route path="/forgot-pswd" element={<Forget_pswd />} />
          <Route path="/self-learning" element={<Self_learning />} />
          <Route path="/webinar" element={<MainWebinar/>}/>
        </Routes>
        
        <CookieConsent
            onAccept={handleAccept}
      location="bottom"
      buttonText="Accept"
      cookieName="cookieConsent"
      style={{ background: 'rgba(0, 0, 0, 0.8)', animation: 'fadeIn 0.5s',fontSize:"18px",fontFamily:"Inter",paddingRight:"150px" }} // Apply animation style
    >
      Askmeidentity uses cookies to ensure you get the best experience.
    </CookieConsent>
      </>
    );
  } else {
    return (
      <>
     <div className="containerStyle">
    <a href="https://api.whatsapp.com/send?phone=8184814546" className="whatsappStyle">
      <img src={Whatsup} alt="" />
    </a>
  </div>
        <Routes>
          <Route path="/faq" element={<Faq />} />
          <Route path="/form" element={<Editinfo />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/term" element={<Terncondition />} />
          <Route path="/admin" element={<Admindashboard />} />
          <Route path="/iam-consultant" element={<Consultant />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dash />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/training-detail" element={<Training />} />
          <Route path="/mainblog" element={<Mainblog />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/consultant" element={<Consultant />} />
          <Route path="/term" element={<Term />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/idam" element={<Idam />} />
          <Route path="/services" element={<AllSevicesComp />} />
          <Route path="/training" element={<Training />} />
          {/* <Route path='/payment' element={<Payment_page/>}/> */}
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/askmeidentity-training" element={<New_Training />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/user-overview" element={<Overview />} />
          <Route path="/all-training" element={<All_training />} />   
                 <Route path="/self-learning" element={<Self_learning />} />

         <Route path='/events' element={<Event/>}/>
        </Routes>
        {/* <div>
         <a href="https://api.whatsapp.com/send?phone=9175061511"><img src={Whatsup} alt="" /></a>
        </div> */}
        <CookieConsent
            onAccept={handleAccept}
      location="bottom"
      buttonText="Accept"
      cookieName="cookieConsent"
      style={{ background: 'rgba(0, 0, 0, 0.8)', animation: 'fadeIn 0.5s',fontSize:"18px",fontFamily:"Inter",paddingRight:"150px" }} // Apply animation style
    >
      Askmeidentity uses cookies to ensure you get the best experience.
    </CookieConsent>
      </>
    );
  }
}

export default App;
